.landing {
  height: 100vh;
  display: grid;
  grid-template-columns: 100vw;
}

.landing-avatar {
  align-self: end;
  justify-self: center;
}

.landing-avatar img {
  width: 40vmin;
  max-width: 280px;
  min-width: 100px;
  border: 8px double var(--color-bg-light);
  border-radius: 100%;
}

.landing-reflection {
  align-self: start;
  justify-self: center;
  mask-image: linear-gradient(transparent 10%, white 100%);
  filter: opacity(20%);
  transform: scaleY(-1);
}

.landing-title {
  align-self: end;
  justify-self: center;
}

.landing-title-text {
  font-family: var(--font-code);
  font-weight: 100;
  font-size: calc(65px + 4vw);
  line-height: 0.7;
  margin: 0;
}

@media screen and (min-width: 840px) {
  .landing {
    grid-template-columns: 2fr 3fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "landing-avatar landing-title"
      "landing-avatar landing-reflection";
    column-gap: 4rem;
  }
  .landing-avatar {
    grid-area: landing-avatar;
    align-self: center;
    justify-self: end;
  }

  .landing-title {
    grid-area: landing-title;
    justify-self: start;
  }

  .landing-reflection {
    grid-area: landing-reflection;
    justify-self: start;
  }

  .landing-title-text {
    font-size: calc(90px + 3vw);
  }
}
